@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@400;500&family=Rubik:wght@400;500;600;700;800;900&family=Roboto&display=swap");

@font-face {
  font-family: "Cheva Display";
  src: url("../public/fonts/cheva-display.ttf") format("truetype");
}

* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

html {
  font-size: 62.5%;
}

a {
  text-decoration: none;
  color: inherit;
}

body {
  font-family: "Poppins", sans-serif;
  font-weight: 400;
  overflow: hidden;
  background-color: #270A4B;
  
}

.center {
  margin: 0 auto;
}

.center-inline {
  display: block;
  width: max-content;
  margin: 0 auto;
}

.center-absolute {
  left: 0;
  right: 0;
  margin-left: auto;
  margin-right: auto;
  width: max-content;
}

.container {
  background-color: #3c126f;
  color: #ffffff;
  max-width: 48em;
  height: 100vh;
  position: relative;
  overflow-y: auto;
  overflow-x: hidden !important;
}

.container {
  -ms-overflow-style: none;
  scrollbar-width: none;
}

.container::-webkit-scrollbar {
  display: none;
}

.assured-win-container ,.assured-win-container__landing{
  color: #EDA3FF;
  width: max-content;
  margin-top: 1.6em;
  margin-bottom: 2.4em;
  display: flex;
  gap: 0.8em;
}

.assured-win-container > span {
  color: #EDA3FF;
  font-size: 1.4rem;
}
.assured-win-container__landing {
  color: #fff097;
  font-size: 1.2rem;
}

.back-arrow {
  position: absolute;
  top: 16px;
  left: 16px;
  z-index: 1;
  cursor: pointer;
}

@supports (-webkit-touch-callout: none) {
  .ios-media {
    height: 8em !important;
  }
}

body * {
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}
