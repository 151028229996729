@import url("https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap");

.homeTopMandala,
.homeBottomMandala {
  width: 100%;
}

.closeButton {
  position: absolute;
  top: 0;
  right: 0;
  padding: 2em;
  cursor: pointer;
}

.offerHeading {
  position: absolute;
  top: 10.1rem;
  left: 50%;
  transform: translateX(-50%);
  font-family: Cheva Display;
  font-weight: 400;
}

.offerHeadingLineOne {
  display: flex;
  font-size: 3.1rem;
  align-items: baseline;
}

.diya {
  width: 1em;
  margin: 0 0.5em;
}

.offerHeadingLineTwo {
  font-size: 4rem;
}

.cracker1,
.cracker2 {
  position: absolute;
  top: 15em;
}

.cracker1 {
  left: 0;
}

.cracker2 {
  right: 0;
}

.offerSubheading {
  margin: auto;
  width: 28rem;
}

.dubaiContainer {
  width: 75%;
  min-height: 14.7em;
  position: relative;
  cursor: pointer;
  margin: 7rem auto 1rem auto;
}

.dubaiImage {
  width: 100%;
  height: 14.7rem;
  border-radius: 1.3rem;
}

.dubaiText {
  position: absolute;
  width: 90%;
  top: 1em;
  left: 2em;
}

.dubaiLineOneWrapper {
  display: flex;
  justify-content: space-between;
  align-items: baseline;
}

.dubaiLineOne {
  color: #431a74;
  font-size: 1.6rem;
  display: flex;
  flex-direction: column;
  margin-bottom: 0.5em;
}

.dubaiLineOne > img {
  width: 3em;
}

.dubaiLineTwo {
  color: #3c126f;
  font-family: Cheva Display;
  font-size: 3.1rem;
}

.phoneTvContainer {
  width: 75%;
  display: flex;
  justify-content: space-between;
  margin-top: 0.5em;
}

.phoneContainer {
  width: 47%;
  padding-top: 1.5em;
  background-color: white;
  height: 10.5rem;
  min-height: 8.1em;
  font-weight: 500;
  display: flex;
  flex-direction: column;
  position: relative;
  cursor: pointer;
  border-radius: 1.3rem;
}

.phoneLineOne {
  align-self: flex-end;
  color: #3c126f;
  margin-right: 1em;
  font-family: "Poppins", sans-serif;
  font-weight: 700;
}

.phoneLineTwo {
  align-self: center;
  font-size: 0.9rem;
  margin-top: -1em;
  color: #3c126f;
  font-weight: 700;
}

.phoneContainer > img {
  position: absolute;
  bottom: 0;
}

.phoneContainer > img {
  right: 2em;
  width: 4.5em;
}

.participateContainer {
  border: 1px solid #fcb803;
  width: 90%;
  border-radius: 20px;
  padding: 1.4em;
  position: relative;
  background-color: #24054b;
  margin-bottom: 1.2em;
}

.participateContainer > .participateHeading {
  font-size: 1.4rem;
  font-weight: 500;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 0.8em;
  margin-bottom: 1.4rem;
}

.buttonContainer {
  display: flex;
  flex-direction: column;
  gap: 1.6em;
}

.buttonContainer > a > button {
  width: 95%;
  border-radius: 25px;
  border: 0;
  padding: 0.5em;
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: #fff;
  cursor: pointer;
  color: #07244b;
}

.iconGroup {
  display: flex;
  align-items: center;
  gap: 0.4rem;
}

.iconPadding {
  background: #f7f7fc;
  padding: 0.7em 0.8em;
  border-radius: 50%;
}

.iconGroup > span {
  font-size: 1.6rem;
  font-weight: 500;
}

.buttonContainer > a > button > img {
  margin-right: 1.5em;
}

.cracker3,
.cracker4 {
  position: absolute;
}

.cracker3 {
  left: 0;
  top: 730px;
}

.cracker4 {
  right: 0;
  top: 640px;
}

.riveContainer {
  position: absolute;
  top: 0;
  left: 50%;
  transform: translateX(-50%) translateY(-34%);
  z-index: 1;
  width: 95%;
  height: 45rem ;
}

.dateDuration {
  margin-top: 2rem;
  background: linear-gradient(180deg, #FFDF21 0%, #E9B700 100%);
  background-clip: text; 
  -webkit-background-clip: text;
  color: transparent; 
  font-size: 1.88rem;
  font-weight: 400;
  font-family: "Poppins";
  text-align: center;
  
}

.piceLogo{
  width: 7rem;
}