@import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');

.backArrow {
  position: absolute;
  top: 16px;
  left: 16px;
  z-index: 1;
  cursor: pointer;
}

.faq {
  position: absolute;
  top: 10px;
  right: 18px;
  font-size: 1.4rem;
  font-weight: 500;
  z-index: 100;
  cursor: pointer;
  padding: 1em;
}

.mandalaContainer {
  position: relative;
}

.mandalaTop {
  width: 100%;
}

.textContainer {
  display: flex;
  flex-direction: column;
  position: absolute;
  align-items: center;
  top: 45%;
  gap: 0.3em;
}

.heading {
  font-size: 2.4rem;
  font-weight: 500;
}

.daysLeft {
  font-size: 1.2rem;
  display: flex;
  align-items: center;
  gap: 0.6em;
  border-radius: 23px;
  background: #581AA4;
  padding: 0.5em 1em;
}

.divider {
  display: flex;
  width: 100%;
  position: absolute;
  bottom: 0;
  justify-content: center;
}

.subheading {
  font-size: 1.8rem;
  margin-top: 1em;
  margin-bottom: 1.4em;
  font-weight: 500;
}

.description {
  font-size: 1.4rem;
  color: #EDA3FF;
  margin-left: 2em;
}

.description > ul {
  margin-left: 1.5em;
}

.leaderboard {
  background-color: #f6f6f6;
  width: 90%;
  border-radius: 16px;
  padding: 1.6em;
  margin-top: 2.4em;
}

.leaderboardHeading {
  color: #767676;
  font-size: 1.4rem;
  display: flex;
  justify-content: space-between;
}

.leaderboardDivider {
  width: 100%;
}

.leaderboardEntry {
  padding: 0.8em 0 0 0;
  color: #0a0a0a;
  display: flex;
  align-items: baseline;
  justify-content: space-between;
}

.nameVolume > .name {
  font-size: 1.4rem;
  font-weight: 500;
  display: flex;
  flex-direction: column;

}

.nameVolume > .volume {
  font-size: 1.2rem;
  font-family: Roboto;
  color: #767676;
}

.prize {
  display: flex;
  font-size: 1.4rem;
  gap: 0.8em;
  width: 33%;
  justify-content: flex-start;
  align-items: center;
}

.prize > span {
  width: max-content;
}

.myRankContainer {
  position: sticky;
  bottom: 0;
  display: flex;
  align-items: center;
  border: solid 0.1px #fcab03;
  border-bottom: none;
  height: 7.4em;
  border-radius: 30px 30px 0 0;
  padding: 0 2.4em;
  background-color: #3C126F;
  justify-content: space-between;
}

.myRankContainer > .rank {
  display: flex;
  align-items: center;
  gap: 0.8em;
}

.rank > div {
  padding: 1em 0.9em 0.5em 1em;
  background-color: #5A058D;
  border-radius: 50%;
  text-align: center;
}

.rank > span {
  font-size: 1.4rem;
}

.myRankContainer > span {
  color: #EDA3FF;
  font-size: 1.2rem;
  font-family: Roboto;
  margin-right: 0.8em;
}

.riveContainer {
  position: absolute;
  top: 0;
  left: 50%;
  transform: translateX(-47.4%) translateY(-36%);
  z-index: 10;
  width: 95%;
  height: 45rem ;
}

.piceLogo{
  width: 7rem;
}